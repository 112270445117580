import * as React from "react";

import { StaticImage } from "gatsby-plugin-image";
import Page from "../components/Page";
import Container from "../components/Container";

import { PDFwhite } from "../images/svg";
const IndexPage = () => {
  return (
    <Page
      metaTitle="Saint Gobain Kitchen Shutters in Aizawl, Mizoram"
      metaDescription="Craft world-class kitchens and interior spaces with a comprehensive range of ready-to-use shutter solutions from Saint-Gobain"
      className="kitchen-page not-front"
    >
      <section className="under-construction">
        <Container>
          <figure>
            <StaticImage
              src="../images/saint-gobain-upvc-windows-aizawl-mizoram.png"
              alt="Saint Gobain Kitchen Shutters, Aizawl Mizoram"
              loading="eager"
              placeholder="none"
              layout="fullWidth"
            />
          </figure>

          <h1>
            <span>Saint Gobain</span>
            <strong>Kitchen Shutters</strong> <span>in Aizawl, Mizoram</span>
          </h1>
          <p>Page Under Construction</p>
          <div className="link">
            <a href="/Saint-Gobain-Inspire-KitchenShutter.pdf" target="_blank">
              <PDFwhite />
              <b>Download Brochure</b>
            </a>
          </div>
        </Container>
      </section>
    </Page>
  );
};

export default IndexPage;
